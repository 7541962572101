import { Category } from "@/common/entities/categories/Category";
import { SeoDetails } from "@/common/entities/seo/SeoDetails";
import { Products } from "@/common/entities/product/Product";
import { ProductAttributesCharacteristics } from "@/common/entities/product/Attributes";
import { ConfiguratorImage } from "@/common/entities/product/ProductImage";

export enum ConfiguratorType {
    last = "Last",
    first = "First",
}
export enum ConfiguratorStepType {
    select = "select",
    filter = "filter",
    input = "input",
    selectDropdown = "selectDropdown",
}

export interface Configurators {
    id: number;
    name: string;
    category: Category;
    active: boolean;
    description: string;
    shortDescription: string;
    seoDetails: SeoDetails;
    url: string;
    totalCost: number;
    configuratorImages: ConfiguratorImage[];
    type: ConfiguratorType;
    configuratorSteps: ConfiguratorsSteps[];
    includedProductsObservation?: string;
}

export interface ConfiguratorsStepsCharacteristics {
    id: number;
    configuratorStep: ConfiguratorsSteps;
    characteristic: ProductAttributesCharacteristics;
    position: number;
    clients: ConfigurationStepsProducts[];
}

export interface ConfiguratorsSteps {
    id: number;
    name: string;
    configurator: Configurators;
    type: ConfiguratorStepType;
    position: number;
    isFilterable: boolean;
    isRequired: boolean;
    configuratorStepsProducts?: Products[];
    configuratorStepsCharacteristics?: ConfiguratorsStepsCharacteristics[];
}

export interface ConfigurationStepsProducts {
    id: number;
    configuratorStep: ConfiguratorsSteps;
    product: Products;
    isPopular: boolean;
    position: number;
}

export interface ConfiguratorsPaginatedResponse {
    data: Configurators[];
    total: number;
}
