export interface CreateContactDto {
    email: string;
    phone?: string;
    firstName: string;
    lastName: string;
    subject: string;
    message: string;
    siteId: number | undefined;
    productId?: number | undefined;
    recaptchaToken: "";
}

export const initContactsDto = () => {
    return {
        email: "",
        phone: "",
        firstName: "",
        lastName: "",
        subject: "",
        message: "",
        siteId: 0,
        productId: undefined,
        recaptchaToken: "",
        gdprAgreement: false,
    };
};

export interface CreateRequestContactDto {
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
    subject: string;
    message: string;
    siteId: number;
    productId: number;
    clientId: number | undefined;
    gdprAgreement: boolean;
    recaptchaToken: string;
}

export const initRequestOffer: CreateRequestContactDto = {
    email: "",
    phone: "",
    firstName: "",
    lastName: "",
    subject: "",
    message: "",
    siteId: 0,
    productId: 0,
    clientId: undefined,
    gdprAgreement: false,
    recaptchaToken: "",
};
