import {backendUrl} from "@/lib/Constants";
import {CreateContactDto, CreateRequestContactDto} from "@/common/entities/contact/Contact";
import {doPost} from "@/common/helpers/fetch.helpers";

export async function sendContactMessage(data: any): Promise<CreateContactDto> {
    return await doPost(`${backendUrl}/contact`, data);
}

export async function sendRequestOfferProduct(data: CreateRequestContactDto): Promise<any> {
    return await doPost(`${backendUrl}/contact/offer-request`, data);
}
