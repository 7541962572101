import { Breadcrumb, BreadcrumbItemProps, Container } from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import styles from "./Breadcrumb.module.scss";
import Link from "next/link";
import * as React from "react";

interface BreadcrumbProps {
    breadcrumbItems: BreadcrumbItemProps[] | undefined;
}

const PBreadcrumb = ({ breadcrumbItems }: BreadcrumbProps) => {
    return (
        <div className={styles.BreadCrumbWrapper}>
            <Container>
                <Breadcrumb className={styles.BreadCrumbP}>
                    <Link className="breadcrumb-item" href="/">
                        <FaHome /> Acasa
                    </Link>
                    {breadcrumbItems &&
                        breadcrumbItems?.length > 0 &&
                        breadcrumbItems?.map((breadCrumb, index) => (
                            <Link
                                className={`breadcrumb-item  ${index === breadcrumbItems.length - 1 ? "active" : ""}`}
                                href={breadCrumb.href ? breadCrumb.href : "#"}
                                key={index}
                            >
                                {breadCrumb.title}
                            </Link>
                        ))}
                </Breadcrumb>
            </Container>
        </div>
    );
};

export default PBreadcrumb;
