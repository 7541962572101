"use client";

import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { StringInput } from "@/components/common/Form/StringInput";
import { TextareaInput } from "@/components/common/Form/TextareaInput";
import { CreateRequestContactDto, initRequestOffer } from "@/common/entities/contact/Contact";
import { NumberInput } from "@/common/components/Form/NumberInput";
import { SitesBasic } from "@/common/entities/sites/SitesBasic";
import { sendRequestOfferProduct } from "@/services/ContactService";
import toast from "react-hot-toast";
import { FaRegEnvelope } from "react-icons/fa";
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";
import Link from "next/link";

const RModal = styled(Modal)`
    .modal-body {
        background: #eee;
    }
`;

interface AddProductToCartProps {
    productId: number;
    productName: string;
    siteBasicData: SitesBasic;
}

const phoneRegExp = /^[0-9]{10}$/;

const RequestOfferProduct = ({ productId, productName, siteBasicData }: AddProductToCartProps) => {
    const [show, setShow] = useState(false);
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const recaptchaSiteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

    const handleClose = () => setShow(false);
    const handleShow = async () => {
        setShow(true);
    };

    const formik = useFormik({
        initialValues: { ...initRequestOffer, siteId: siteBasicData?.id ?? 0, productId: productId },
        validationSchema: Yup.object({
            firstName: Yup.string().required("Acest camp este obligatoriu.").max(200).required("Acest camp este obligatoriu."),
            lastName: Yup.string().required("Acest camp este obligatoriu.").max(200).required("Acest camp este obligatoriu."),
            email: Yup.string().email("Nu este o adresa de email valida."),
            subject: Yup.string().required("Acest camp este obligatoriu.").min(3, "Minim 3 caractere necesare.").max(200),
            message: Yup.string().required("Acest camp este obligatoriu.").min(10, "Minim 10 caractere necesare.").max(5000),
            phone: Yup.string().matches(phoneRegExp, "Formatul numarului de telefon nu este valid.").required("Acest camp este obligatoriu."),
            gdprAgreement: Yup.boolean().isTrue("Poți continua doar dacă accepți Politica de confidentialitate a datelor."),
        }),
        onSubmit: async (values: CreateRequestContactDto, { resetForm }) => {
            try {
                if (recaptchaRef.current) {
                    const token = await recaptchaRef.current.executeAsync();
                    recaptchaRef.current.reset();

                    if (!token) {
                        toast.error("Failed to generate reCAPTCHA token. Please try again.");
                        return;
                    }
                    const isHuman = token.length > 0;

                    if (!isHuman) {
                        toast.error("reCAPTCHA validation failed.");
                        return;
                    }

                    values.recaptchaToken = token;
                } else {
                    toast.error("Failed to initialize reCAPTCHA. Please try again.");
                }

                const isOfferSent = await sendRequestOfferProduct(values);

                if (isOfferSent) {
                    toast.success("Cererea de oferta a fost trimisa!");
                    resetForm();
                    handleClose();
                } else {
                    toast.error("Cererea de oferta nu s-a putut trimite!");
                }
            } catch (error: any) {
                toast.error(error.message);
            }
        },
    });

    useEffect(() => {
        if (siteBasicData?.id) {
            formik.setFieldValue("siteId", siteBasicData.id);
        }
    }, [siteBasicData]);

    const onGdprAgreementChange = () => {
        formik.setFieldValue("gdprAgreement", !formik.values.gdprAgreement);
    };

    return (
        <>
            <Button size="lg" variant="primary" onClick={handleShow}>
                <FaRegEnvelope className="text-white me-2" /> Cere oferta
            </Button>

            <RModal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Oferta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <h4 className="text-center mt-2">
                            <strong>Cere oferta pentru {productName}</strong>
                        </h4>
                        <p className="text-center mt-2">
                            Completeaza formularul de mai jos, iar un coleg din echipa noastra te va contacta in maximum 24h lucratoare!
                        </p>
                        <form onSubmit={formik.handleSubmit}>
                            <NumberInput formik={formik} field={"siteId"} type={"hidden"} labelName={"siteId"} />
                            <NumberInput formik={formik} field={"productId"} type={"hidden"} labelName={"productId"} />
                            <Row>
                                <Col xs={12} md={6}>
                                    <StringInput formik={formik} field={"firstName"} type={"text"} labelName={"Prenume"} required={true} />
                                </Col>
                                <Col xs={12} md={6}>
                                    <StringInput formik={formik} field={"lastName"} type={"text"} labelName={"Nume"} required={true} />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} md={6}>
                                    <StringInput formik={formik} field={"email"} type={"text"} labelName={"Email"} />
                                </Col>
                                <Col xs={12} md={6}>
                                    <StringInput
                                        formik={formik}
                                        field={"phone"}
                                        type={"text"}
                                        labelName={"Telefon"}
                                        placeholder={"+40xxxxxxxxx"}
                                        required={true}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <StringInput formik={formik} field={"subject"} type={"text"} labelName={"Subiect"} required={true} />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <TextareaInput formik={formik} field={"message"} rows={5} labelName={"Mesajul"} required={true} />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Check
                                        type="checkbox"
                                        id="gdprAgreement"
                                        name="gdprAgreement"
                                        label={
                                            <span>
                                                Sunt de acord cu{" "}
                                                <Link href="/politica-de-confidentialitate" target="_blank" style={{ color: "var(--primary-color)" }}>
                                                    politica de confidentialitate a datelor *
                                                </Link>
                                            </span>
                                        }
                                        checked={formik.values.gdprAgreement}
                                        onChange={onGdprAgreementChange}
                                    />
                                    {formik.touched.gdprAgreement && formik.errors.gdprAgreement ? (
                                        <div className="text-danger mt-3">{formik.errors.gdprAgreement}</div>
                                    ) : null}
                                </Col>
                            </Row>

                            <Button type="submit" variant="primary" className="d-block border-0 w-100 py-3 mt-3" disabled={!formik.isValid}>
                                Trimite mesajul
                            </Button>
                        </form>
                    </Container>
                    {recaptchaSiteKey && <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaSiteKey} size="invisible" />}
                </Modal.Body>
            </RModal>
        </>
    );
};

export default RequestOfferProduct;
