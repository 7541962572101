interface ErrorInputsProps {
    text: string;
    className?: string;
}

const ErrorInputs = ({ text, className }: ErrorInputsProps) => {
    return (
        <div className={className ? className : ""}>
            <p className="errorInput">{text}</p>
        </div>
    );
};

export default ErrorInputs;
