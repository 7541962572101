"use client";

import React, { useState } from "react";
import { Button, FormControl, InputGroup, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import style from "../productPage.module.scss";
import { FaMinus, FaPlus } from "react-icons/fa";
import LocalStorageService from "@/services/localStorageService/LocalStorageService";
import { LocalStorageKeys } from "@/services/localStorageService/LocalStorageKeys";
import toast from "react-hot-toast";
import { Products } from "@/common/entities/product/Product";
import { sendCartData, updateCartProducts } from "@/services/CartService";
import { CartItem } from "@/common/entities/cart/CartItem";

interface AddProductToCartProps {
    product: Products;
    quantityGlassProduct?: number;
}

const AddProductToCart = ({ product }: AddProductToCartProps) => {
    const minQtyToOrder = product.productStock?.minQtyToOrder || 1;
    const [quantity, setQuantity] = useState(minQtyToOrder);
    const [isAddingToCart, setIsAddingToCart] = useState<boolean>(false);

    const increment = () => setQuantity(quantity + 1);
    const decrement = () => setQuantity(quantity > minQtyToOrder ? quantity - 1 : minQtyToOrder);
    const changeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value, 10);
        if (!isNaN(value) && value >= minQtyToOrder) {
            setQuantity(value);
        }
    };

    const addToCart = async () => {
        setIsAddingToCart(true);
        const cartItems = LocalStorageService.getItem(LocalStorageKeys.cart) || { cartProducts: [] };
        let cartId = LocalStorageService.getItem(LocalStorageKeys.cartId);

        try {
            if (!cartId) {
                const cartResponse = await sendCartData({ productId: product.id, quantity });
                cartId = cartResponse.id;
                LocalStorageService.setItem(LocalStorageKeys.cartId, cartId, 3600 * 24 * 30);
                LocalStorageService.setItem(LocalStorageKeys.cart, cartResponse, 3600 * 24 * 30);
                setIsAddingToCart(false);
            } else {
                const existingCartItem = cartItems.cartProducts.find((item: CartItem) => item.product.id === product.id);
                if (existingCartItem) {
                    const updatedQuantity = Number(existingCartItem.quantity) + quantity;
                    const updatedCart = await updateCartProducts(cartId, {
                        cartProductId: existingCartItem.id,
                        quantity: updatedQuantity,
                    });
                    LocalStorageService.setItem(LocalStorageKeys.cartId, updatedCart.id, 3600 * 24 * 30);
                    LocalStorageService.setItem(LocalStorageKeys.cart, updatedCart, 3600 * 24 * 30);
                    setIsAddingToCart(false);
                } else {
                    const newCart = await sendCartData({ cartId, productId: product.id, quantity });
                    LocalStorageService.setItem(LocalStorageKeys.cartId, newCart.id, 3600 * 24 * 30);
                    LocalStorageService.setItem(LocalStorageKeys.cart, newCart, 3600 * 24 * 30);
                    setIsAddingToCart(false);
                }
            }
            toast.success(`${product.name} a fost adaugat in cos.`);
        } catch (error) {
            LocalStorageService.clear();
            console.error("Error adding product to cart:", error);
            toast.error("A aparut o eroare, te rugam adauga produsul din nou in cos.");
            setIsAddingToCart(false);
        }
    };

    return (
        <div className={style.actionWrapper}>
            {!product.glass && (
                <InputGroup className={style.inputGroup}>
                    {product.productStock && product.productStock.minQtyToOrder > 1 ? (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    Cantitatea minima pentru comanda este <strong>{product.productStock.minQtyToOrder} buc</strong>
                                </Tooltip>
                            }
                        >
                            <Button className={style.decrement} variant="outline-secondary" onClick={decrement} disabled={quantity === minQtyToOrder}>
                                <FaMinus />
                            </Button>
                        </OverlayTrigger>
                    ) : (
                        <Button className={style.decrement} variant="outline-secondary" onClick={decrement} disabled={quantity === minQtyToOrder}>
                            <FaMinus />
                        </Button>
                    )}

                    <FormControl value={quantity} onChange={changeInput} type="number" min={minQtyToOrder} className="text-center" />
                    <Button className={style.increment} variant="outline-secondary" onClick={increment}>
                        <FaPlus />
                    </Button>
                </InputGroup>
            )}

            <Button variant="primary" className={style.addToCartBtn} onClick={!isAddingToCart ? addToCart : undefined} disabled={isAddingToCart}>
                {isAddingToCart ? <Spinner animation="border" size="sm" /> : ""} Adauga in Cos
            </Button>
        </div>
    );
};

export default AddProductToCart;
