"use client";

import {Form, FormGroup} from "react-bootstrap";
import ErrorInputs from "../../../components/ErrorInputs/ErrorInputs";

interface TextareaInputProps {
    formik: any;
    field: string;
    rows: number;
    max?: number;
    labelName: string;
    resize?: boolean;
    required?: boolean;
}

export const TextareaInput = ({ formik, field, labelName, max, rows = 4, resize = true, required = false }: TextareaInputProps) => {
    const checkMaxInput = (oldValue: number, newValue: number) => {
        if (oldValue === newValue) {
            return "text-danger";
        }
    };

    return (
        <FormGroup className="mb-3">
            <Form.Label htmlFor={field} className="d-flex align-items-center justify-content-between">
                <div>{labelName}
                    {required && <span className="text-danger ms-1">*</span>}</div>

                {max && (
                    <div className={checkMaxInput(max, formik.values[field].length)}>
                        {formik.values[field].length}/{max}
                    </div>
                )}
            </Form.Label>
            <Form.Control
                className={resize ? "form-control" : "resize-none form-control"}
                as="textarea"
                id={field}
                name={field}
                rows={rows}
                value={formik.values[field]}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                maxLength={max}
            />
            {formik.touched[field] && formik.errors[field] ? <ErrorInputs text={formik.errors[field]} /> : null}
        </FormGroup>
    );
};
