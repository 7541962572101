"use client";

import React, { useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import styles from "@/components/ConfiguratorsProducts/configuratorStepSelect.module.scss";
import { formatNumberWithDecimals } from "@/common/helpers/util.functions";
import { Products, ProductType } from "@/common/entities/product/Product";
import styled from "styled-components";
import { FaCheck, FaCopy, FaEye, FaShareAlt } from "react-icons/fa";
import ProductsUtils from "@/common/utils/ProductsUtils";
import { ProductsPacks } from "@/common/entities/product/SimilarProducts";

interface FloatingProductsConfiguratorData {
    products: Products[];
    observations?: string;
    configuratorTitle: string;
    totalPrice: number;
    btnCustomClass?: string;
}

const RModal = styled(Modal)`
    .modal-body {
        background: #eee;
    }
`;

const FloatingProductsConfigurator = ({ products, observations, configuratorTitle, totalPrice, btnCustomClass }: FloatingProductsConfiguratorData) => {
    const [show, setShow] = useState(false);
    const [copied, setCopied] = useState(false);

    const calculateUnitPrice = (product: Products) => {
        if ([ProductType.glass, ProductType.door].includes(product.type) && product.height && product.length) {
            const areaInSquareMeters = (Number(product.height) * Number(product.length)) / 1000000;
            return (Number(product.specialPriceWithVat) ? Number(product.specialPriceWithVat) : Number(product.priceWithVat)) * areaInSquareMeters;
        }
        return product.specialPriceWithVat ? Number(product.specialPriceWithVat) : Number(product.priceWithVat);
    };

    const handleClose = () => setShow(false);

    const handleShow = async () => {
        setShow(true);
    };

    const setStringSelectedProducts = () => {
        let isPromotion: boolean = false;
        let finalString: string = "";
        finalString += "*" + configuratorTitle + "*" + "\n";
        finalString += "Produse incluse: \n";

        products.map((stepProduct: any) => {
            const productObj: Products = stepProduct?.product ? stepProduct.product : stepProduct;
            if (productObj.type === ProductType.pack && ProductsUtils.checkIfPackProductsHavePrice(productObj)) {
                productObj.packedProducts.map((packedProduct) => {
                    isPromotion = packedProduct.packedProduct.specialPriceWithVat ? true : false;
                    finalString +=
                        packedProduct.quantity +
                        " x " +
                        packedProduct.packedProduct.name +
                        " = " +
                        formatNumberWithDecimals(
                            (packedProduct.packedProduct.specialPriceWithVat
                                ? packedProduct.packedProduct.specialPriceWithVat
                                : packedProduct.packedProduct.priceWithVat) * packedProduct.quantity,
                        ) +
                        " lei \n";
                });
            } else {
                isPromotion = productObj.specialPriceWithVat ? true : false;
                finalString +=
                    "1 x " +
                    productObj.name +
                    " = " +
                    formatNumberWithDecimals(productObj.specialPriceWithVat ? productObj.specialPriceWithVat : productObj.priceWithVat) +
                    " lei \n";
            }
        });

        finalString += observations + " \n";
        finalString += "*Total pret" + (isPromotion ? " redus: " : ": ") + totalPrice + " lei*";

        return finalString;
    };

    const handleCopy = async () => {
        const configuratorText = setStringSelectedProducts();
        try {
            //navigator works only on HTTPS
            await navigator.clipboard.writeText(configuratorText);
            setCopied(true);
        } catch (error) {
            console.error(error);
            // alert('Lista nu a putut fi copiata!');
        }
    };

    const handleShare = async () => {
        const configuratorText = setStringSelectedProducts();
        try {
            //navigator works only on HTTPS
            await navigator.share({
                text: configuratorText,
            });
        } catch (error) {
            console.error(error);
            // alert('Browser-ul folosit nu este compatibil cu aceasta functie!');
        }
    };

    const getPriceByPackedProduct = (unitPrice: number, quantity: number, packedProduct: ProductsPacks) => {
        if (Number(unitPrice) !== 0) {
            return <>{formatNumberWithDecimals(unitPrice * quantity)} Lei</>;
        }

        if (
            (packedProduct?.originProductPack?.specialPriceWithVat && Number(packedProduct.originProductPack.specialPriceWithVat) !== 0) ||
            (packedProduct?.originProductPack?.priceWithVat && Number(packedProduct.originProductPack.priceWithVat) !== 0)
        ) {
            return <>{formatNumberWithDecimals(packedProduct?.originProductPack?.specialPriceWithVat ?? packedProduct?.originProductPack?.priceWithVat)} Lei</>;
        } else {
            return <>{formatNumberWithDecimals(Number(totalPrice) / quantity)} Lei</>;
        }
    };

    return (
        <>
            <Button className={`${btnCustomClass} ${styles.seeBtn}`} variant="primary" onClick={handleShow}>
                <span className={styles.seeBtnText}>Vezi produse incluse</span>
                <FaEye className="text-white me-md-2" />
            </Button>
            <RModal size="lg" show={show} onHide={handleClose}>
                <Modal.Header className="align-items-start" closeButton>
                    <Modal.Title>
                        <div>Produse incluse</div>
                        <div>
                            <Button variant="default" className="p-0 border-0 me-2" onClick={handleCopy}>
                                {!copied ? <FaCopy /> : <FaCheck />} Copiaza lista
                            </Button>
                            <Button variant="default" className="p-0 border-0" onClick={handleShare}>
                                <FaShareAlt /> Distribuie lista
                            </Button>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <div>
                            {products &&
                                products.map((product: any, index) => {
                                    const productObj: Products = product?.product ? product.product : product;

                                    return (
                                        <React.Fragment key={index}>
                                            {productObj.type === ProductType.pack ? (
                                                productObj.packedProducts.map((packedProduct: ProductsPacks, packedIndex) => {
                                                    const unitPrice = calculateUnitPrice(packedProduct.packedProduct);
                                                    const quantity = Number(packedProduct.quantity);

                                                    return (
                                                        <Row key={packedIndex} className={`${styles.productRow} g-0 mt-2`}>
                                                            <Col md={8}>
                                                                <div className="text-start">
                                                                    <span>
                                                                        {quantity} X {packedProduct.packedProduct.name}
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                            <Col md={4}>
                                                                <div className="text-end">
                                                                    <span className={styles.productPrice}>
                                                                        {getPriceByPackedProduct(unitPrice, quantity, packedProduct)}
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    );
                                                })
                                            ) : (
                                                <Row className={`${styles.productRow} g-0 mt-2`}>
                                                    <Col md={8}>
                                                        <div className={`${styles.productQuantity} text-start`}>
                                                            <span>1 X {productObj.name}</span>
                                                        </div>
                                                    </Col>
                                                    <Col md={4}>
                                                        <div className="text-end">
                                                            <span className={styles.productPrice}>
                                                                {formatNumberWithDecimals(calculateUnitPrice(productObj))} Lei
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )}
                                        </React.Fragment>
                                    );
                                })}

                            <p className="mt-2 mb-2 text-center">{observations}</p>
                        </div>
                    </Container>
                </Modal.Body>
            </RModal>
        </>
    );
};

export default FloatingProductsConfigurator;
