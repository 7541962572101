"use client";

import React, { useCallback, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Configurators } from "@/common/entities/configurators/Configurator";
import PBreadcrumb from "@/components/Breadcrumb";
import style from "@/components/ConfiguratorsProducts/configuratorStepSelect.module.scss";
import ConfiguratorPrice from "@/components/ConfiguratorsProducts/ConfiguratorPrice";
import ConfiguratorSteps from "@/components/ConfiguratorsProducts/ConfiguratorSteps";
import ProductThumbnailGallery from "@/components/ProductThumbnailGallery/ProductThumbnailGallery";
import ReadMore from "../ReadMore/ReadMore";

interface ConfiguratorDetailsProps {
    configurator: Configurators;
    isMobile?: boolean;
    breadcrumbItems: any[];
}

interface ConfiguratorOptions {
    stepId: number;
    cardId?: number;
    name?: string;
    value?: number | string;
}

const ConfiguratorDetails = ({ configurator, isMobile, breadcrumbItems }: ConfiguratorDetailsProps) => {
    const [completedSteps, setCompletedSteps] = useState<Set<number>>(new Set());
    const [selectedOptions, setSelectedOptions] = useState<ConfiguratorOptions[]>([]);
    const [configuratorState, setConfiguratorState] = useState(configurator);
    const [resetSteps, setResetSteps] = useState<boolean>(false);

    if (!breadcrumbItems) breadcrumbItems = [{ title: configurator?.name, active: true }];

    const handleUpdateSelectedOptions = useCallback((options: ConfiguratorOptions[]) => {
        setSelectedOptions(options);
    }, []);

    const handleUpdateCompletedSteps = useCallback((steps: Set<number>) => {
        setCompletedSteps(steps);
    }, []);

    const handleResetSteps = useCallback(() => {
        setCompletedSteps(new Set());
        setSelectedOptions([]);
        setConfiguratorState(configurator);
        setResetSteps(true);
        setTimeout(() => {
            setResetSteps(false);
        }, 50);
    }, []);

    return (
        <>
            <PBreadcrumb breadcrumbItems={breadcrumbItems} />
            <Container className="mt-4">
                <Row className="mb-4 mt-2">
                    <Col md={5} className="mt-4 mt-md-0">
                        <div>
                            <h4 className={style.configuratorTitle}>{configurator.name}</h4>
                        </div>

                        <div className="mt-4">
                            {configurator.configuratorImages && (
                                <ProductThumbnailGallery
                                    defaultImage={configurator.configuratorImages.find((image) => image.cover) || configurator.configuratorImages[0]}
                                    images={configurator.configuratorImages}
                                    isMobile={isMobile}
                                />
                            )}
                        </div>
                        {configurator.description &&
                            (isMobile ? (
                                <div className={`${style.configuratorDescription} my-4`}>
                                    <ReadMore text={configurator.description} maxCharNumber={250} />
                                </div>
                            ) : (
                                <div className={`${style.configuratorDescription} mt-4`} dangerouslySetInnerHTML={{ __html: configurator.description }}></div>
                            ))}
                    </Col>
                    <Col md={4} className={`${style.columnBorder} mb-4`}>
                        <ConfiguratorSteps
                            configurator={configuratorState}
                            onUpdateSelectedOptions={handleUpdateSelectedOptions}
                            onUpdateCompletedSteps={handleUpdateCompletedSteps}
                            resetSteps={resetSteps}
                        />
                    </Col>
                    <Col md={3}>
                        <ConfiguratorPrice
                            completedSteps={completedSteps.size}
                            configurator={configuratorState}
                            selectedOptions={selectedOptions}
                            resetSteps={handleResetSteps}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ConfiguratorDetails;
