import {backendUrl} from "@/lib/Constants";
import {doGet, doPost, doPut} from "@/common/helpers/fetch.helpers";
import {AddCartProductDto, Cart, UpdateCartDataDto} from "@/common/entities/cart/CartItem";
import {PaymentMethod} from "@/common/entities/paymentMethods/PaymentMethod";
import {OnlinePaymentProvider} from "@/common/entities/order/OrderOnlinePayment";

export interface PaymentMethodOnCartDto {
    paymentId: number;
    installmentProvider?: OnlinePaymentProvider;
}

export enum PaymentMethodsList {
    payAtDelivery = "payAtDelivery",
}

export async function sendCartData(data: AddCartProductDto): Promise<Cart> {
    return doPost(`${backendUrl}/carts/add-product`, data);
}

export async function updateCartProducts(cartId: number, data: UpdateCartDataDto): Promise<Cart> {
    return doPut(`${backendUrl}/carts/${cartId}/update-quantity`, data);
}

export async function deleteProductCart(cartId: number, cartProductId: number): Promise<Cart> {
    return doPut(`${backendUrl}/carts/${cartId}/remove/${cartProductId}`);
}

export async function deleteConfiguratorCartProductsFromCart(cartId: number, configuratorCode: string): Promise<Cart> {
    return doPut(`${backendUrl}/carts/${cartId}/remove-configurator/${configuratorCode}`);
}

export async function getPaymentMethods(productsIds: number[]): Promise<PaymentMethod[] | undefined> {
    const params: Record<any, any> = {
        productIds: productsIds.join(","),
        onlyPublicVisible: true
    };

    return doGet<PaymentMethod[]>(`${backendUrl}/carts/available-payments`, params);
}

export async function getPublicPaymentMethods(productsIds: number[]): Promise<PaymentMethod[] | undefined> {
    const params: Record<string, string> = {
        productIds: productsIds.join(","),
    };

    return doGet<PaymentMethod[]>(`${backendUrl}/payment-methods/public`, params);
}

export async function setPaymentMethod(cartId: number, data: PaymentMethodOnCartDto): Promise<Cart> {
    return doPut(`${backendUrl}/carts/${cartId}/payment-method`, data);
}
