"use client";

import React, { useState } from "react";
import { Alert, Button, Col, Container, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { Products } from "@/common/entities/product/Product";
import styled from "styled-components";
import LocalStorageService from "@/services/localStorageService/LocalStorageService";
import { LocalStorageKeys } from "@/services/localStorageService/LocalStorageKeys";
import toast from "react-hot-toast";
import { sendCartData } from "@/services/CartService";

interface AddProductToCartProps {
    product: Products;
}

const DisplayArea = styled.div`
    background-color: #e1e0e0;
    min-height: 250px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    position: relative;
`;

const GrassInputGroup = styled(InputGroup)`
    border: 1px solid #333;

    span:first-child {
        min-width: 115px;
    }

    &.error {
        border: 1px solid #9d0000;
        .input-group-text {
            background: #9d0000;
        }
        .last {
            background: #fff;
        }
    }

    .input-group-text {
        border: none;
        background: #333;
        border-radius: 0;
        color: #fff;
    }

    .last {
        padding-left: 0;
    }
    .form-control {
        padding: 1rem 0.5rem;
    }

    .last,
    .form-control {
        border: 0;
        background: #fff;
        color: #333;
    }
`;

const GCard = styled.div`
    border: 0;
    padding: 1.55rem;
    background: #fff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.05);
`;

const ScaledRectangle = styled.div`
    background-color: #e6e0ad;
    border: 2px solid #af7213;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 1.5rem;
    color: darkblue;
    font-weight: bold;
    position: absolute; // Positioned absolutely to center it within the parent
    max-height: 100%;
    max-width: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    transition:
        height 0.5s ease,
        width 0.5s ease;
`;

const WidthInfo = styled.div`
    font-size: 11px;
    color: #333;
    position: absolute;
    top: 8px;
    left: 8px;
`;

const HeightInfo = styled.div`
    color: #333;
    font-size: 11px;
    position: absolute;
    top: 15px;
    right: -32px;
    transform: rotate(90deg);
    transform-origin: 50% 50%;
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
`;

const GlassModal = ({ product }: AddProductToCartProps) => {
    const [editedHeight, setEditedHeight] = useState<string>("0");
    const [editedLength, setEditedLength] = useState<string>("0");
    const [quantityItems, setQuantityItems] = useState<string>("1");
    const [heightError, setHeightError] = useState<string>("");
    const [lengthError, setLengthError] = useState<string>("");
    const [isAddingToCart, setIsAddingToCart] = useState<boolean>(false);

    const MIN_DIMENSION = 40;

    const calculateQuantity = () => {
        const height = parseFloat(editedHeight);
        const length = parseFloat(editedLength);
        return Number((height * length) / 1000000);
    };

    const calculateTotalPrice = () => {
        const quantity = calculateQuantity();
        if (product && Number(quantity)) {
            const price = product.priceWithVat * Number(quantity) * Number(quantityItems);
            return Number(price) ? price : null;
        }
        return null;
    };

    const totalPrice = calculateTotalPrice();

    const maxDisplayHeight = 220;
    const maxDisplayLength = 440;

    const heightScale = parseFloat(editedHeight) > maxDisplayHeight ? maxDisplayHeight / parseFloat(editedHeight) : 1;
    const lengthScale = parseFloat(editedLength) > maxDisplayLength ? maxDisplayLength / parseFloat(editedLength) : 1;

    const scaleFactor = Math.min(heightScale, lengthScale);

    const displayHeight = parseFloat(editedHeight) * scaleFactor;
    const displayLength = parseFloat(editedLength) * scaleFactor;

    const validateDecimalInput = (value: string) => {
        return /^(\d+)?([.]?\d{0,2})?$/.test(value);
    };

    const handleInputHeight = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        if (validateDecimalInput(inputValue)) {
            setEditedHeight(inputValue);
            if (inputValue === "" || parseFloat(inputValue) >= MIN_DIMENSION) {
                setHeightError("");
            } else {
                setHeightError(`Minim ${MIN_DIMENSION} mm.`);
            }
        }
    };

    const handleInputLength = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        if (validateDecimalInput(inputValue)) {
            setEditedLength(inputValue);
            if (inputValue === "" || parseFloat(inputValue) >= MIN_DIMENSION) {
                setLengthError("");
            } else {
                setLengthError(`Minim ${MIN_DIMENSION} mm.`);
            }
        }
    };

    const handleInputQuantityItem = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        if (inputValue === "") {
            setQuantityItems("");
        } else if (validateDecimalInput(inputValue)) {
            const numericalValue = parseInt(inputValue, 10);
            if (numericalValue > 10) {
                setQuantityItems("10");
            } else {
                setQuantityItems(inputValue);
            }
        }
    };

    const handleAddToCart = async () => {
        setIsAddingToCart(true);
        let cartId = LocalStorageService.getItem(LocalStorageKeys.cartId);

        try {
            if (!cartId) {
                const cartResponse = await sendCartData({
                    productId: product.id,
                    quantity: calculateQuantity(),
                    height: Number(editedHeight),
                    length: Number(editedLength),
                    replicate: Number(quantityItems),
                });
                cartId = cartResponse.id;
                LocalStorageService.setItem(LocalStorageKeys.cartId, cartId, 3600 * 24 * 30);
                LocalStorageService.setItem(LocalStorageKeys.cart, cartResponse, 3600 * 24 * 30);
            } else {
                const newCart = await sendCartData({
                    cartId: cartId,
                    productId: product.id,
                    quantity: calculateQuantity(),
                    height: Number(editedHeight),
                    length: Number(editedLength),
                    replicate: Number(quantityItems),
                });
                LocalStorageService.setItem(LocalStorageKeys.cartId, newCart.id, 3600 * 24 * 30);
                LocalStorageService.setItem(LocalStorageKeys.cart, newCart, 3600 * 24 * 30);
            }
            toast.success(`${product.name} a fost adaugat in cos.`);
            setIsAddingToCart(false);
        } catch (error) {
            LocalStorageService.clear();
            console.error("Error adding product to cart:", error);
            toast.error("A aparut o eroare, te rugam adauga produsul din nou in cos.");
            setIsAddingToCart(false);
        }
    };

    return (
        <>
            <Container>
                <GCard>
                    <Row>
                        <Col md={7}>
                            <Row className="mt-2">
                                <Col md={4}>
                                    <GrassInputGroup className={`${heightError ? "error" : ""} mb-3`}>
                                        <InputGroup.Text id="height">{heightError ? "Min 40 mm" : "Inaltime"}</InputGroup.Text>
                                        <Form.Control
                                            placeholder="Inaltime"
                                            aria-label="height"
                                            aria-describedby="height"
                                            onChange={handleInputHeight}
                                            value={editedHeight}
                                        />
                                        <InputGroup.Text className="last">mm</InputGroup.Text>
                                    </GrassInputGroup>
                                </Col>
                                <Col md={4}>
                                    <GrassInputGroup className={`${lengthError ? "error" : ""} mb-3`}>
                                        <InputGroup.Text id="length">{lengthError ? "Min 40 mm" : "Latime"}</InputGroup.Text>
                                        <Form.Control
                                            placeholder="Latime"
                                            aria-label="length"
                                            aria-describedby="length"
                                            onChange={handleInputLength}
                                            value={editedLength}
                                        />
                                        <InputGroup.Text className="last">mm</InputGroup.Text>
                                        <Form.Control.Feedback type="invalid">{lengthError}</Form.Control.Feedback>
                                    </GrassInputGroup>
                                </Col>
                                <Col md={4}>
                                    <GrassInputGroup className="mb-3">
                                        <InputGroup.Text id="quantity">Cantitate</InputGroup.Text>
                                        <Form.Control
                                            placeholder="quantity"
                                            aria-label="quantity"
                                            aria-describedby="quantity"
                                            onChange={handleInputQuantityItem}
                                            value={quantityItems}
                                        />
                                        <InputGroup.Text className="last">Buc</InputGroup.Text>
                                    </GrassInputGroup>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <Alert className="mt-1" variant="danger">
                                        Dimensiunile se dau în mm! 1cm = 10mm
                                    </Alert>
                                </Col>
                            </Row>

                            <Row className="mt-4">
                                <Col xs={12} md={6}>
                                    <div>{product?.name}</div>
                                    <h5 className="mt-3">Pret: {totalPrice && totalPrice > 0 ? totalPrice.toFixed(2) : 0} LEI</h5>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Button
                                        disabled={Number(editedLength) <= MIN_DIMENSION || Number(editedHeight) <= MIN_DIMENSION || isAddingToCart}
                                        variant="primary"
                                        className="p-3 px-5 mt-3 mt-md-0 mb-4 mb-md-0"
                                        onClick={!isAddingToCart ? handleAddToCart : undefined}
                                    >
                                        {isAddingToCart ? <Spinner animation="border" size="sm" /> : ""} Adauga in Cos
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={5}>
                            <DisplayArea>
                                <ScaledRectangle style={{ height: `${displayHeight}px`, width: `${displayLength}px` }}>
                                    <WidthInfo>{`${editedLength} mm`}</WidthInfo>
                                    <HeightInfo>{`${editedHeight} mm`}</HeightInfo>
                                </ScaledRectangle>
                            </DisplayArea>
                        </Col>
                    </Row>
                </GCard>
            </Container>
        </>
    );
};

export default GlassModal;
