import {Form, FormGroup} from "react-bootstrap";
import React from "react";

export interface NumberInputProps {
    formik: any;
    field: string;
    type: "number" | "hidden";
    max?: number;
    labelName: string;
}

export const NumberInput = ({ formik, field, type, labelName, max }: NumberInputProps) => {
    const checkMaxInput = (value: number) => {
        if (max && value > max) {
            return "text-danger";
        }
        return "";
    };

    return (
        <FormGroup className="mb-3">
            {type !== "hidden" && (
                <Form.Label htmlFor={field} className="d-flex align-items-center justify-content-between">
                    <div>{labelName}</div>
                    {max && (
                        <div className={checkMaxInput(formik.values[field])}>
                            {formik.values[field]}/{max}
                        </div>
                    )}
                </Form.Label>
            )}
            <Form.Control
                className="form-control"
                type={type}
                id={field}
                name={field}
                value={formik.values[field]}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                    const value = parseInt(e.target.value);
                    formik.handleChange({
                        target: {
                            name: field,
                            value: isNaN(value) ? "" : value,
                        },
                    });
                }}
                max={max}
            />
            {formik.touched[field] && formik.errors[field] ? <div className="text-danger">{formik.errors[field]}</div> : null}
        </FormGroup>
    );
};
