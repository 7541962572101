"use client";

import React, { useState } from "react";
import { Button, Col, Container, Form, Row, Image, Spinner } from "react-bootstrap";
import LocalStorageService from "@/services/localStorageService/LocalStorageService";
import { LocalStorageKeys } from "@/services/localStorageService/LocalStorageKeys";
import { sendCartData } from "@/services/CartService";
import style from "./productPage.module.scss";
import rightExterior from "@/images/dreapta-exterior.png";
import rightInterior from "@/images/dreapta-interior.png";
import leftExterior from "@/images/stanga-exterior.png";
import leftInterior from "@/images/stanga-interior.png";
import rightDoorExterior from "@/images/dreapta-usa-exterior.png";
import rightDoorInterior from "@/images/dreapta-usa-interior.png";
import leftDoorExterior from "@/images/stanga-usa-exterior.png";
import leftDoorInterior from "@/images/stanga-usa-interior.png";
import styled from "styled-components";
import { FaShoppingCart } from "react-icons/fa";
import toast from "react-hot-toast";

const GCard = styled.div`
    border: 0;
    padding: 1.55rem;
    background: #fff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.05);
`;

const DoorModal = ({ product }: any) => {
    const [editedHeight, setEditedHeight] = useState<number>(0);
    const [editedLength, setEditedLength] = useState<number>(0);
    const [widthInputError, setWidthInputError] = useState<string>("");
    const [heightInputError, setHeightInputError] = useState<string>("");
    const [hingePosition, setHingePosition] = useState("left");
    const [mountingType, setMountingType] = useState("interior");
    const [inAdvance, setInAdvance] = useState(false);
    const [noReturn, setNoReturn] = useState(false);
    const [isAddingToCart, setIsAddingToCart] = useState<boolean>(false);

    const minValue = 400;
    const maxValue = 800;
    const inputMaxLength = maxValue.toString().length;

    const calculateQuantity = () => {
        return Number(((editedHeight * editedLength) / 1000000).toFixed(2));
    };

    const calculateTotalPrice = () => {
        const quantity = calculateQuantity();
        if (Number(quantity)) {
            const price = product.priceWithVat * Number(quantity);
            return Number(price) ? (price >= 2475 ? price : 2475) : 0;
        }
        return 0;
    };

    const totalPrice = calculateTotalPrice();

    const rangeRestrict = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const value = parseInt(e.currentTarget.value);
        if (isNaN(value)) return false;

        const elementId = e.currentTarget.id;
        if (value < minValue) {
            if (elementId == "glassHeight") setHeightInputError(`Minim ${minValue} mm`);
            if (elementId == "glassWidth") setWidthInputError(`Minim ${minValue} mm`);
        } else if (value > maxValue) {
            if (elementId == "glassHeight") setHeightInputError(`Maxim ${maxValue} mm`);
            if (elementId == "glassWidth") setWidthInputError(`Maxim ${maxValue} mm`);
        } else {
            if (elementId == "glassHeight") setHeightInputError("");
            if (elementId == "glassWidth") setWidthInputError("");

            return true;
        }
        return false;
    };

    const validateDecimalInput = (value: string) => {
        return /^(\d+)?([.]?\d{0,2})?$/.test(value);
    };

    const handleInputLength = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        if (validateDecimalInput(inputValue)) {
            const inputNumber = parseInt(inputValue);
            if (isNaN(inputNumber)) {
                setEditedLength(0);
            } else {
                setEditedLength(inputNumber);
            }
        }
    };

    const handleInputHeight = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        if (validateDecimalInput(inputValue)) {
            const inputNumber = parseInt(inputValue);
            if (isNaN(inputNumber)) {
                setEditedHeight(0);
            } else {
                setEditedHeight(inputNumber);
            }
        }
    };

    const handleAddToCart = async () => {
        setIsAddingToCart(true);
        let cartId = LocalStorageService.getItem(LocalStorageKeys.cartId);

        try {
            if (!cartId) {
                const cartResponse = await sendCartData({
                    productId: product.id,
                    quantity: calculateQuantity(),
                    height: Number(editedHeight),
                    length: Number(editedLength),
                    hingePosition: hingePosition,
                    mountingType: mountingType,
                });
                cartId = cartResponse.id;
                LocalStorageService.setItem(LocalStorageKeys.cartId, cartId, 3600 * 24 * 30);
                LocalStorageService.setItem(LocalStorageKeys.cart, cartResponse, 3600 * 24 * 30);
            } else {
                const newCart = await sendCartData({
                    cartId: cartId,
                    productId: product.id,
                    quantity: calculateQuantity(),
                    height: Number(editedHeight),
                    length: Number(editedLength),
                    hingePosition: hingePosition,
                    mountingType: mountingType,
                });
                LocalStorageService.setItem(LocalStorageKeys.cartId, newCart.id, 3600 * 24 * 30);
                LocalStorageService.setItem(LocalStorageKeys.cart, newCart, 3600 * 24 * 30);
            }
            toast.success(`${product.name} a fost adaugat in cos.`);
            setIsAddingToCart(false);
        } catch (error) {
            LocalStorageService.clear();
            console.error("Error adding product to cart:", error);
            toast.error("A aparut o eroare, te rugam adauga produsul din nou in cos.");
            setIsAddingToCart(false);
        }
    };

    return (
        <>
            <Container>
                <GCard className={style.doorProductWrapper}>
                    <Row>
                        <Col md={7}>
                            <Row>
                                <Col>
                                    <div className={style.stepTitle}>1. Calculează preț {product.name}</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <div className={style.dimension}>
                                        <div className={`${style.dimensionTitle} ${heightInputError ? "bg-danger" : ""}`}>
                                            {heightInputError ? heightInputError : "INALTIME"}
                                        </div>
                                        <div className={style.inputWrapper}>
                                            <Form.Control
                                                id="glassHeight"
                                                type="text"
                                                min={minValue}
                                                max={maxValue}
                                                value={editedHeight}
                                                onChange={handleInputHeight}
                                                onKeyUp={rangeRestrict}
                                                minLength={1}
                                                maxLength={inputMaxLength}
                                            />
                                            <div className={style.afterInput}>mm</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6}>
                                    <div className={style.dimension}>
                                        <div className={`${style.dimensionTitle} ${widthInputError ? "bg-danger" : ""}`}>
                                            {widthInputError ? widthInputError : "LATIME"}
                                        </div>
                                        <div className={style.inputWrapper}>
                                            <Form.Control
                                                id="glassWidth"
                                                type="text"
                                                min={minValue}
                                                max={maxValue}
                                                value={editedLength}
                                                onChange={handleInputLength}
                                                onKeyUp={rangeRestrict}
                                                minLength={1}
                                                maxLength={inputMaxLength}
                                            />
                                            <div className={style.afterInput}>mm</div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <div style={{ color: "red" }}>Dimensiunile se dau in mm! 1cm = 10mm</div>
                                </Col>
                            </Row>
                            <Row className="d-block d-md-none mt-2">
                                <Col>
                                    <div className={style.doorImageWrapper}>
                                        <Image
                                            className={style.imageDoor}
                                            fluid
                                            alt={product.name}
                                            src={
                                                hingePosition === "right" && mountingType === "exterior"
                                                    ? rightExterior.src
                                                    : hingePosition === "right" && mountingType === "interior"
                                                      ? rightInterior.src
                                                      : hingePosition === "left" && mountingType === "exterior"
                                                        ? leftExterior.src
                                                        : leftInterior.src
                                            }
                                        />
                                        <div className={style.doorHeight}>{editedHeight > 0 && !heightInputError ? editedHeight + " mm" : "Înălțime"}</div>
                                        <div className={style.doorLength}>{editedLength > 0 && !widthInputError ? editedLength + " mm" : "Lățime"}</div>
                                    </div>
                                </Col>
                            </Row>

                            <hr className="my-4" />

                            <Row>
                                <Col>
                                    <div className={style.stepTitle}>2. Pozitie balamale</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4} className="mb-2">
                                    <Form.Check
                                        inline
                                        type="radio"
                                        id="hingePositionLeft"
                                        name="hingePositionLeft"
                                        label="Stânga"
                                        value="left"
                                        checked={hingePosition === "left"}
                                        onChange={() => setHingePosition("left")}
                                    />
                                </Col>
                                <Col md={4} className="mb-2">
                                    <Form.Check
                                        inline
                                        type="radio"
                                        id="hingePositionRight"
                                        name="hingePositionRight"
                                        label="Dreapta"
                                        value="right"
                                        checked={hingePosition === "right"}
                                        onChange={() => setHingePosition("right")}
                                    />
                                </Col>
                            </Row>

                            <hr className="my-4" />

                            <Row>
                                <Col>
                                    <div className={style.stepTitle}>3. Tip montaj</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4} className="mb-2">
                                    <Form.Check
                                        inline
                                        type="radio"
                                        id="mountingTypeInterior"
                                        name="mountingTypeInterior"
                                        label="Interior"
                                        value="interior"
                                        checked={mountingType === "interior"}
                                        onChange={() => setMountingType("interior")}
                                    />
                                </Col>
                                <Col md={4} className="mb-2">
                                    <Form.Check
                                        inline
                                        type="radio"
                                        id="mountingTypeExterior"
                                        name="mountingTypeExterior"
                                        label="Exterior"
                                        value="exterior"
                                        checked={mountingType === "exterior"}
                                        onChange={() => setMountingType("exterior")}
                                    />
                                </Col>
                            </Row>

                            <hr className="my-4" />

                            <Row>
                                <Col>
                                    <div className={style.stepTitle}>4. Acord comandă specială</div>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col>
                                    <Form.Check
                                        inline
                                        type="checkbox"
                                        id="noReturn"
                                        label="Am luat la cunoștință că produsul nu se poate returna"
                                        name="noReturn"
                                        checked={noReturn === true}
                                        onChange={(e) => setNoReturn(e.target.checked)}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col>
                                    <Form.Check
                                        inline
                                        type="checkbox"
                                        id="inAdvance"
                                        label="Am luat la cunoștință că plata se face doar în avans"
                                        name="inAdvance"
                                        checked={inAdvance === true}
                                        onChange={(e) => setInAdvance(e.target.checked)}
                                    />
                                </Col>
                            </Row>

                            <Row className="mt-4">
                                <Col xs={12}>
                                    <div className={style.doorPrice}>PRET: {totalPrice.toFixed(2)} LEI</div>
                                </Col>
                                <Col xs={12} className="mt-2">
                                    <Button
                                        disabled={editedLength <= minValue || editedHeight <= minValue || !inAdvance || !noReturn || isAddingToCart}
                                        variant="primary"
                                        className={style.customProductAddBtn}
                                        onClick={!isAddingToCart ? handleAddToCart : undefined}
                                    >
                                        {isAddingToCart ? <Spinner animation="border" size="sm" className="me-2" /> : <FaShoppingCart className="me-2" />}{" "}
                                        Adauga in Cos
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={5} lg={{ span: 4, offset: 1 }} className="d-none d-md-block">
                            <Col>
                                <Row>
                                    <Col>
                                        <div className={style.doorImageWrapper}>
                                            <Image
                                                fluid
                                                alt={product.name}
                                                className={style.imageDoor}
                                                src={
                                                    hingePosition === "right" && mountingType === "exterior"
                                                        ? rightExterior.src
                                                        : hingePosition === "right" && mountingType === "interior"
                                                          ? rightInterior.src
                                                          : hingePosition === "left" && mountingType === "exterior"
                                                            ? leftExterior.src
                                                            : leftInterior.src
                                                }
                                            />
                                            <div className={style.doorHeight}>{editedHeight > 0 && !heightInputError ? editedHeight + " mm" : "Înălțime"}</div>
                                            <div className={style.doorLength}>{editedLength > 0 && !widthInputError ? editedLength + " mm" : "Lățime"}</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <div className={style.doorImageWrapper}>
                                            <Image
                                                fluid
                                                alt={product.name}
                                                className={style.imageDoor}
                                                src={
                                                    hingePosition === "right" && mountingType === "exterior"
                                                        ? rightDoorExterior.src
                                                        : hingePosition === "right" && mountingType === "interior"
                                                          ? rightDoorInterior.src
                                                          : hingePosition === "left" && mountingType === "exterior"
                                                            ? leftDoorExterior.src
                                                            : leftDoorInterior.src
                                                }
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                    </Row>
                </GCard>
            </Container>
        </>
    );
};

export default DoorModal;
