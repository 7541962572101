import React, { useEffect, useState, useCallback, useRef } from "react";
import { Configurators, ConfiguratorsSteps, ConfiguratorStepType } from "@/common/entities/configurators/Configurator";
import style from "@/components/ConfiguratorsProducts/configuratorStepSelect.module.scss";
import { Row, Col } from "react-bootstrap";
import { FaCheckCircle, FaChevronDown, FaChevronUp } from "react-icons/fa";
import StepProductList from "@/components/ConfiguratorsProducts/StepProduct";
import { getFilteredProducts } from "@/services/ConfiguratorService";
import { Products } from "@/common/entities/product/Product";
import dynamic from "next/dynamic";

const SelectNoSSR = dynamic(() => import("react-select"), { ssr: false });

interface CategoryProductsListProps {
    configurator: Configurators;
    onUpdateSelectedOptions: (options: ConfiguratorOptions[]) => void;
    onUpdateCompletedSteps: (steps: Set<number>) => void;
    resetSteps?: boolean;
}

interface ConfiguratorOptions {
    stepId: number;
    cardId?: number;
    value?: number | string;
    characteristicIds?: number[];
    isPopular?: boolean;
    id?: number;
}

const ConfiguratorSteps = ({ configurator, onUpdateSelectedOptions, onUpdateCompletedSteps, resetSteps }: CategoryProductsListProps) => {
    const [selectedValues, setSelectedValues] = useState<Record<number, string | undefined>>({});
    const [completedSteps, setCompletedSteps] = useState<Set<number>>(new Set());
    const [openStep, setOpenStep] = useState<number[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<ConfiguratorOptions[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const numberRequiredSteps = configurator.configuratorSteps.filter((step) => step.isRequired).length;
    const inputTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (resetSteps) {
            setSelectedValues({});
            setCompletedSteps(new Set());
            setOpenStep([]);
            setSelectedOptions([]);
        }
    }, [configurator, resetSteps]);

    const fetchFilteredProducts = useCallback(
        async (stepId: number, characteristicIds: number[]) => {
            setIsLoading(true);
            try {
                const filteredProducts = await getFilteredProducts(stepId, characteristicIds);
                configurator.configuratorSteps = configurator.configuratorSteps.map((step) =>
                    step.id === stepId ? { ...step, configuratorStepsProducts: filteredProducts } : step,
                ) as ConfiguratorsSteps[];
            } catch (error) {
                console.error("Error fetching filtered products:", error);
            } finally {
                setIsLoading(false);
            }
        },
        [configurator.configuratorSteps],
    );

    useEffect(() => {
        onUpdateSelectedOptions(selectedOptions);
        onUpdateCompletedSteps(completedSteps);
    }, [selectedOptions, completedSteps, onUpdateSelectedOptions, onUpdateCompletedSteps]);

    const resetSubsequentSteps = (stepIndex: number) => {
        setSelectedOptions((prevOptions) => prevOptions.filter((option) => option.stepId <= stepIndex));

        setCompletedSteps((prevCompletedSteps) => {
            const updatedSteps = new Set(Array.from(prevCompletedSteps).filter((step) => step <= stepIndex));
            return updatedSteps;
        });
        setSelectedValues((prevValues) => {
            const updatedValues = Object.fromEntries(Object.entries(prevValues).filter(([key]) => Number(key) <= stepIndex));
            return updatedValues;
        });

        setOpenStep((prev: any) => prev.filter((step: any) => step <= stepIndex));
    };

    const handleChange = useCallback(
        (stepIndex: number, value: string, characteristicIds: number[]) => {
            resetSubsequentSteps(stepIndex);

            setSelectedValues((prev) => {
                const updatedValues = { ...prev, [stepIndex]: value };

                setCompletedSteps((prevCompletedSteps) => {
                    const updatedCompletedSteps = new Set(prevCompletedSteps);
                    if (value) {
                        updatedCompletedSteps.add(stepIndex);
                    } else {
                        updatedCompletedSteps.delete(stepIndex);
                    }

                    setSelectedOptions((prevOptions) => {
                        const updatedOptions = prevOptions.filter((option) => option.stepId !== stepIndex);
                        updatedOptions.push({ stepId: stepIndex, value, characteristicIds });

                        // Collect characteristic IDs from completed previous steps and current step
                        const allCharacteristicIds = configurator.configuratorSteps
                            .slice(0, stepIndex)
                            .filter((_, index) => updatedCompletedSteps.has(index)) // Only completed steps
                            .flatMap((step, index) => {
                                const selectedOption = selectedOptions.find((option) => option.stepId === index);
                                return selectedOption?.characteristicIds || [];
                            })
                            .concat(characteristicIds); // Include current step's IDs

                        let nextStepIndex = stepIndex + 1;

                        // Fetch next steps if they are filterable or optional
                        if (nextStepIndex < configurator.configuratorSteps.length) {
                            const nextStep = configurator.configuratorSteps[nextStepIndex];

                            if ([ConfiguratorStepType.select, ConfiguratorStepType.selectDropdown].includes(nextStep.type) && nextStep.isFilterable) {
                                fetchFilteredProducts(nextStep.id, allCharacteristicIds);
                            }

                            nextStepIndex++;
                        }

                        return updatedOptions;
                    });

                    return updatedCompletedSteps;
                });
                return updatedValues;
            });
        },
        [configurator.configuratorSteps, selectedOptions, fetchFilteredProducts],
    );

    const handleCardSelect = useCallback(
        (stepIndex: number, cardIndex: number) => {
            if (!configurator?.configuratorSteps?.length || !configurator?.configuratorSteps[stepIndex]?.configuratorStepsProducts?.length) {
                return;
            }

            // If it's an optional step and already selected, deselect it
            const selectedOption = selectedOptions.find((option) => option.stepId === stepIndex);
            if (selectedOption?.cardId === cardIndex && !configurator?.configuratorSteps[stepIndex].isRequired) {
                setSelectedOptions((prevOptions) => prevOptions.filter((option) => option.stepId !== stepIndex));
                setCompletedSteps((prevCompletedSteps) => {
                    const updatedCompletedSteps = new Set(prevCompletedSteps);
                    updatedCompletedSteps.delete(stepIndex);
                    return updatedCompletedSteps;
                });
                return;
            }

            const productObj: any = configurator?.configuratorSteps[stepIndex]?.configuratorStepsProducts[cardIndex];

            const cardPrice: number = productObj.specialPriceWithVat ?? productObj.priceWithVat;
            const productIsPopular: boolean = productObj?.configuratorStepProduct?.isPopular || false;

            setSelectedOptions((prevOptions) => {
                const updatedOptions = prevOptions.filter((option) => option.stepId !== stepIndex);
                updatedOptions.push({ stepId: stepIndex, cardId: cardIndex, value: cardPrice, isPopular: productIsPopular });

                const nextStepIndex = stepIndex + 1;
                if (nextStepIndex < configurator.configuratorSteps.length) {
                    const nextStep = configurator.configuratorSteps[nextStepIndex];

                    const characteristicIds = selectedOptions.flatMap((option) => option.characteristicIds || []);

                    if ([ConfiguratorStepType.select, ConfiguratorStepType.selectDropdown].includes(nextStep.type) && nextStep.isFilterable) {
                        fetchFilteredProducts(nextStep.id, characteristicIds);
                    }
                }

                return updatedOptions;
            });

            setCompletedSteps((prevCompletedSteps) => {
                const updatedCompletedSteps = new Set(prevCompletedSteps);
                updatedCompletedSteps.add(stepIndex);
                return updatedCompletedSteps;
            });
        },
        [configurator.configuratorSteps, selectedOptions],
    );

    const handleInputChange = useCallback(
        (stepIndex: number, text: string, id: number) => {
            if (inputTimeoutRef.current) clearTimeout(inputTimeoutRef.current);

            inputTimeoutRef.current = setTimeout(() => {
                if (text.length < 2 && configurator?.configuratorSteps[stepIndex].isRequired) {
                    setSelectedOptions((prevOptions: ConfiguratorOptions[]) => prevOptions.filter((option) => option.id !== id));

                    setCompletedSteps((prevCompletedSteps) => {
                        const updatedCompletedSteps = new Set(prevCompletedSteps);
                        updatedCompletedSteps.delete(stepIndex);
                        return updatedCompletedSteps;
                    });
                    return;
                }

                setSelectedOptions((prevOptions) => {
                    const updatedOptions = prevOptions.filter((option) => option.stepId !== stepIndex);
                    updatedOptions.push({ stepId: stepIndex, value: text, id: id });

                    const nextStepIndex = stepIndex + 1;
                    if (nextStepIndex < configurator.configuratorSteps.length) {
                        const nextStep = configurator.configuratorSteps[nextStepIndex];

                        const characteristicIds = selectedOptions.flatMap((option) => option.characteristicIds || []);

                        if ([ConfiguratorStepType.select, ConfiguratorStepType.selectDropdown].includes(nextStep.type) && nextStep.isFilterable) {
                            fetchFilteredProducts(nextStep.id, characteristicIds);
                        }
                    }

                    setOpenStep((prev: any) => [...prev, stepIndex]);

                    return updatedOptions;
                });

                setCompletedSteps((prevCompletedSteps) => {
                    const updatedCompletedSteps = new Set(prevCompletedSteps);
                    updatedCompletedSteps.add(stepIndex);
                    return updatedCompletedSteps;
                });
            }, 200);
        },
        [selectedOptions],
    );

    const renderStepHeader = useCallback(
        (configuratorStep: ConfiguratorsSteps, index: number) => {
            const isCompleted =
                configuratorStep.type === ConfiguratorStepType.input
                    ? !!selectedOptions.find((option) => option?.id === configuratorStep.id && String(option?.value).length >= 2)
                    : selectedOptions.some((option) => option.stepId === index);
            const isDisabled = index > 0 && !completedSteps.has(index - 1) && completedSteps.size < numberRequiredSteps && !isCompleted;

            return (
                <div
                    className={`${style.configuratorStepName} ${isDisabled ? style.disabled : ""}`}
                    role={configuratorStep.type === ConfiguratorStepType.select ? "button" : ""}
                    {...(configuratorStep.type === ConfiguratorStepType.select && {
                        onClick: () => {
                            const characteristicIds = selectedOptions.flatMap((option) => option.characteristicIds || []);
                            if (!configuratorStep.isRequired && !openStep.includes(index)) fetchFilteredProducts(configuratorStep.id, characteristicIds);

                            setOpenStep((prev: any) => (prev.includes(index) ? prev.filter((stepIndex: any) => stepIndex !== index) : [...prev, index]));
                        },
                    })}
                >
                    {index + 1}. {configuratorStep.name}
                    {isCompleted && <FaCheckCircle className="ms-2" />}
                    {configuratorStep.type === ConfiguratorStepType.select &&
                        (openStep.includes(index) ? <FaChevronUp className={style.expandIcon} /> : <FaChevronDown className={style.expandIcon} />)}
                </div>
            );
        },
        [selectedOptions, openStep, setOpenStep],
    );

    const selectComponent = useCallback(
        (step: ConfiguratorsSteps, index: number) => {
            const isDisabled = index > 0 && !completedSteps.has(index - 1);

            if (step.type === ConfiguratorStepType.input) {
                return (
                    <input
                        type="text"
                        className={style.inputCharacteristic}
                        placeholder="Scrie aici.."
                        disabled={isDisabled}
                        onChange={(e) => handleInputChange(index, e.target.value, step.id)}
                    />
                );
            }

            if (step.type === ConfiguratorStepType.filter) {
                return (
                    <SelectNoSSR
                        isSearchable={false}
                        closeMenuOnSelect={true}
                        placeholder="Selecteaza.."
                        className={style.selectCharacteristic}
                        classNamePrefix="configurator-select"
                        isDisabled={isDisabled}
                        instanceId={`step-${step.id}`}
                        inputId={`select-${index}`}
                        value={
                            selectedValues[index]
                                ? { label: selectedValues[index], value: selectedValues[index] }
                                : { label: "Selecteaza..", value: "Selecteaza.." }
                        }
                        onChange={(e: any) => {
                            const selectedValue = e?.value;
                            if (selectedValue) {
                                const selectedCharacteristic = step?.configuratorStepsCharacteristics?.find(
                                    (item) => item.characteristic.name === selectedValue,
                                );

                                const characteristicId = selectedCharacteristic && selectedCharacteristic.characteristic.id;

                                if (characteristicId) {
                                    handleChange(index, selectedValue, [characteristicId]);
                                }
                            }
                        }}
                        options={step?.configuratorStepsCharacteristics
                            ?.sort((a, b) => a.position - b.position)
                            .map((stepCharacteristic) => ({
                                label: stepCharacteristic.characteristic.name,
                                value: stepCharacteristic.characteristic.name,
                            }))}
                    />
                );
            }

            if (step.type === ConfiguratorStepType.select) {
                const selectedCardId = selectedOptions.find((option) => option.stepId === index)?.cardId ?? -1;
                return <StepProductList configuratorsSteps={step} stepIndex={index} cardIndex={selectedCardId} onCardSelect={handleCardSelect} key={index} />;
            }

            if (step.type === ConfiguratorStepType.selectDropdown) {
                const selectedProduct = selectedOptions.find((option) => option.stepId === index)?.cardId;
                const stepProducts: Products[] | undefined = step?.configuratorStepsProducts
                    ?.sort((a, b) => (a.configuratorStepProduct?.position || 0) - (b.configuratorStepProduct?.position || 0))
                    .map((stepProduct: any) => {
                        return stepProduct?.product ? stepProduct.product : stepProduct;
                    });

                const productLabel = selectedProduct !== undefined && selectedProduct >= 0 && stepProducts && stepProducts[selectedProduct].name;

                return (
                    <SelectNoSSR
                        isSearchable={false}
                        closeMenuOnSelect={true}
                        placeholder="Selecteaza.."
                        className={style.selectCharacteristic}
                        classNamePrefix="configurator-select"
                        instanceId={`step-${step.id}`}
                        inputId={`select-${index}`}
                        isDisabled={isDisabled}
                        value={
                            selectedProduct !== undefined && selectedProduct >= 0
                                ? { label: productLabel, value: productLabel }
                                : { label: "Selecteaza..", value: "Selecteaza.." }
                        }
                        onChange={(e: any) => {
                            const selectedValue = e?.value;
                            if (selectedValue) {
                                const selectedIndex = stepProducts?.findIndex((item) => item.name === selectedValue);

                                if (selectedIndex !== undefined && selectedIndex >= 0) {
                                    handleCardSelect(index, selectedIndex);
                                }
                            }
                        }}
                        options={stepProducts?.map((stepProduct: any) => ({
                            label: stepProduct.name,
                            value: stepProduct.name,
                        }))}
                    />
                );
            }

            return null;
        },
        [completedSteps, handleChange, handleCardSelect, selectedOptions, selectedValues, isLoading],
    );

    return (
        <>
            {configurator.configuratorSteps
                .sort((a, b) => a.position - b.position)
                .map((configuratorStep, index) => (
                    <Row className={style.configuratorStepRow} key={index}>
                        <Col>
                            {renderStepHeader(configuratorStep, index)}
                            {([ConfiguratorStepType.filter, ConfiguratorStepType.selectDropdown, ConfiguratorStepType.input].includes(configuratorStep.type) ||
                                openStep.includes(index)) &&
                                selectComponent(configuratorStep, index)}
                        </Col>
                    </Row>
                ))}
        </>
    );
};

export default ConfiguratorSteps;
