"use client";

import {Col, Row} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import HCard from "../Card";

interface LoadingArticlesProps {
    rows: number;
    cols: number;
}

const LoadingArticles = ({ rows = 1, cols }: LoadingArticlesProps) => {
    return (
        <>
            {Array.from({ length: rows }).map((_, rowIndex: number) => (
                <Row key={rowIndex} className="mb-4">
                    {Array.from({ length: cols }).map((_, colIndex: number) => (
                        <Col key={colIndex}>
                            <HCard>
                                <Skeleton height={223} width={"100%"} />
                                <Skeleton height={24} style={{ marginTop: 22 }} />
                                <Skeleton height={14} count={3} style={{ marginTop: 4 }} />
                            </HCard>
                        </Col>
                    ))}
                </Row>
            ))}
        </>
    );
};

export default LoadingArticles;
