"use client";

import React, { useState } from "react";
import { Alert, Card, Col, Row } from "react-bootstrap";
import { FaExclamationTriangle } from "react-icons/fa";
import { ConfiguratorsSteps } from "@/common/entities/configurators/Configurator";
import styles from "@/components/ConfiguratorsProducts/configuratorStepSelect.module.scss";
import { ImageWrapper } from "@/common/components/Images/ImageWrapper";
import { endStringEllipsis, formatNumberWithDecimals } from "@/common/helpers/util.functions";
import ProductsUtils, { ProductImageSizeType } from "@/common/utils/ProductsUtils";
import { Products } from "@/common/entities/product/Product";

interface CategoryProductsListProps {
    configuratorsSteps: ConfiguratorsSteps;
    stepIndex: number;
    cardIndex: number;
    onCardSelect: (stepIndex: number, cardIndex: number) => void;
}

const StepProductList = ({ configuratorsSteps, stepIndex, cardIndex, onCardSelect }: CategoryProductsListProps) => {
    const [selectedCard, setSelectedCard] = useState<number | null>(cardIndex);

    if (!configuratorsSteps || configuratorsSteps.configuratorStepsProducts?.length === 0) {
        return (
            <Row>
                <Col>
                    <Alert variant="secondary" className="d-flex align-items-center mt-2">
                        <FaExclamationTriangle className="me-3" size={40} />
                        Nu s-au gasit produse cu filtrarea actuala.
                    </Alert>
                </Col>
            </Row>
        );
    }

    const handleCardClick = (index: number) => {
        if (selectedCard !== index) {
            setSelectedCard(index);
            onCardSelect(stepIndex, index);
        } else if (!configuratorsSteps.isRequired) {
            setSelectedCard(null);
            onCardSelect(stepIndex, index);
        }
    };

    return (
        <>
            {configuratorsSteps &&
                configuratorsSteps.configuratorStepsProducts
                    ?.sort((a, b) => (a.configuratorStepProduct?.position || 0) - (b.configuratorStepProduct?.position || 0))
                    .map((productStep: any, index) => {
                        const productObj: Products = productStep?.product ? productStep.product : productStep;
                        const isPopular: boolean = productObj?.configuratorStepProduct ? productObj.configuratorStepProduct.isPopular : productStep.isPopular;

                        return (
                            <Card
                                key={index}
                                className={`${styles.productCard} ${selectedCard === index ? styles.selectedCard : ""}`}
                                onClick={() => handleCardClick(index)}
                                role="button"
                            >
                                {isPopular && (
                                    <div className={styles.isPopular}>
                                        <span>Popular</span>
                                    </div>
                                )}
                                <Row className="mt-2">
                                    <Col xs={9} md={8}>
                                        <div>
                                            <div className="d-flex mb-1">
                                                {productObj?.specialPriceWithVat && (
                                                    <div className={styles.productOldPrice}>
                                                        <span>{formatNumberWithDecimals(productObj?.priceWithVat)} lei</span>
                                                    </div>
                                                )}
                                                <div className={`${styles.productPrice} ${productObj?.specialPriceWithVat ? styles.offer : ""} `}>
                                                    {formatNumberWithDecimals(productObj?.specialPriceWithVat ?? productObj?.priceWithVat)} lei
                                                </div>
                                            </div>
                                            <h2 className={styles.productName}>{productObj.name}</h2>
                                            <div
                                                className={styles.productDescription}
                                                dangerouslySetInnerHTML={{
                                                    __html: endStringEllipsis(productObj.shortDescription, 125),
                                                }}
                                            ></div>
                                        </div>
                                    </Col>
                                    <Col xs={3} md={4}>
                                        <ImageWrapper
                                            width={100}
                                            height={100}
                                            className="img-fluid"
                                            url={
                                                productObj.defaultImage
                                                    ? ProductsUtils.getProductCoverImage(ProductImageSizeType.original, productObj.defaultImage)
                                                    : ProductsUtils.getProductCoverImageFromArray(ProductImageSizeType.original, productObj.productImages)
                                            }
                                            title={productObj.name}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        );
                    })}
        </>
    );
};

export default StepProductList;
