"use client";

import { Button, Form, FormGroup, FormGroupProps } from "react-bootstrap";
import ErrorInputs from "../../../components/ErrorInputs/ErrorInputs";
import { slugify } from "@/common/helpers/util.functions";
import { getIn } from "formik";
import { ForwardedRef, forwardRef } from "react";

export interface StringInputProps extends FormGroupProps {
    formik: any;
    field: string;
    type: "text" | "password" | "color";
    max?: number;
    labelName: string;
    toSlugify?: string;
    generateUrl?: boolean;
    disabled?: boolean;
    placeholder?: string;
    required?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const StringInput = forwardRef(
    (
        { required, formik, field, type, labelName, max, toSlugify = "", generateUrl, disabled, placeholder, onChange }: StringInputProps,
        ref: ForwardedRef<HTMLDivElement>,
    ) => {
        const checkMaxInput = (oldValue: number, newValue: number) => {
            if (oldValue === newValue) {
                return "text-danger";
            }
        };

        const generateFriendlyUrl = (value: string) => {
            formik.setFieldValue(field, slugify(value));
        };

        const getFieldNestedValue = () => {
            if (field.includes(".")) {
                return getIn(formik.values, field);
            } else {
                return formik.values[field];
            }
        };

        const fieldTouched = formik.touched[field] !== undefined ? formik.touched[field] : getIn(formik.touched, field);

        return (
            <FormGroup ref={ref} className="mb-4 mt-1 w-100">
                <Form.Label htmlFor={field} className="d-flex align-items-center justify-content-between">
                    <div>
                        {labelName}
                        {required && <span className="text-danger ms-1">*</span>}
                    </div>
                    {max && (
                        <div className={checkMaxInput(max, getFieldNestedValue().length)}>
                            {getFieldNestedValue().length}/{max}
                        </div>
                    )}
                </Form.Label>
                <div className="d-flex align-items-center">
                    <Form.Control
                        className="form-control"
                        type={type}
                        id={field}
                        name={field}
                        value={getFieldNestedValue()}
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                            if (onChange) {
                                onChange(e);
                            }
                            formik.handleChange(e);
                        }}
                        maxLength={max}
                        disabled={disabled}
                        placeholder={placeholder}
                    />

                    {generateUrl && (
                        <Button onClick={() => generateFriendlyUrl(toSlugify)} className="ms-4" size="lg" variant="outline-secondary">
                            Genereaza
                        </Button>
                    )}
                </div>
                {field === "clientDto.email" && formik.errors?.clientDto?.email ? <ErrorInputs text={formik.errors.clientDto.email} /> : <div></div>}

                {field !== "clientDto.email" && fieldTouched && getIn(formik.errors, field) ? <ErrorInputs text={getIn(formik.errors, field)} /> : null}
            </FormGroup>
        );
    },
);
