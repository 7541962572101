"use client";

import React, { useMemo, useState } from "react";
import { Col, Container, Row, Button, Table } from "react-bootstrap";
import style from "../productPage.module.scss";
import { ProductAttribute } from "@/common/entities/product/Attributes";
import { Products } from "@/common/entities/product/Product";

type ProductSpecificationsProps = {
    attributes: ProductAttribute[];
    product: Products;
};

interface TransformedAttribute {
    attribute: string;
    value: string[];
}

const ProductSpecifications: React.FC<ProductSpecificationsProps> = ({ attributes, product }) => {
    const [isClassRemoved, setIsClassRemoved] = useState(false);

    const toggleExpansion = () => {
        setIsClassRemoved(!isClassRemoved);
    };

    const transformedAttributes: TransformedAttribute[] = useMemo(() => {
        return attributes
            .sort((a, b) => a.attribute?.position - b.attribute?.position)
            .reduce<TransformedAttribute[]>((acc, { name, attribute }) => {
                const existingAttribute = acc.find((a) => a.attribute === attribute?.name);

                if (existingAttribute) {
                    existingAttribute.value.push(name);
                } else {
                    acc.push({ attribute: attribute?.name, value: [name] });
                }

                return acc;
            }, []);
    }, [attributes]);

    return (
        <div className={style.productSpecifications}>
            <Container>
                <Row>
                    <Col>
                        <h2 className="sectionTitle">Specificatii</h2>
                        <div className={`${isClassRemoved || transformedAttributes.length <= 6 ? "" : style.tableCaracteristics}`}>
                            <Table className={style.productTableStripped} striped>
                                <tbody>
                                    {transformedAttributes.map((item, index) => (
                                        <tr key={index}>
                                            <td width="20%">{item.attribute}</td>
                                            <td>
                                                {item.value.map((val, valIndex) => (
                                                    <div key={valIndex}>{val}</div>
                                                ))}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>

                        {!isClassRemoved && transformedAttributes.length > 6 && (
                            <p>
                                <Button variant="primary" onClick={toggleExpansion}>
                                    Vezi mai multe
                                </Button>
                            </p>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {product.productUse && (
                            <>
                                <h2 className="sectionTitle">Utilizare</h2>
                                <div dangerouslySetInnerHTML={{ __html: product?.productUse }}></div>
                            </>
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ProductSpecifications;
